import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { IndexComponent } from "./core/components/index/index.component";
import { MasterPageComponent } from "./core/components/master-page/master-page.component";

import { PageContactTwoComponent } from "./core/components/page-contact-two/page-contact-two.component";

const routes: Routes = [
  {
    path: "",
    component: MasterPageComponent,
    children: [
      { path: "", component: IndexComponent },
      { path: "index", component: IndexComponent },
      { path: "contact", component: PageContactTwoComponent },
    ],
  },
  { path: "*", redirectTo: "" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "enabled",
      scrollOffset: [0, 0],
      // Enable scrolling to anchors
      anchorScrolling: "enabled",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
