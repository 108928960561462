import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-page-team",
  templateUrl: "./page-team.component.html",
  styleUrls: ["./page-team.component.css"],
})

/**
 * Page Team Component
 */
export class PageTeamComponent implements OnInit {
  /**
   * Member Data
   */
  memberData = [
    {
      profile: "assets/images/ali.jfif",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Ali Alaswad ",
      designation: "V.P ",
    },
    {
      profile: "assets/images/fadi.png",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Fadie Fouad Sakka",
      designation: "CEO ",
    },
    {
      profile: "assets/images/fuad.jfif",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Fouad Abd Alkadhum ",
      designation: "Basra branch manager  ",
    },
  ];

  constructor() {}

  ngOnInit(): void {}
}
