<!-- TAGLINE START-->

<!-- TAGLINE END-->

<!-- Navbar STart -->
<header
  id="topnav"
  class="defaultscroll sticky"
  (window:scroll)="windowScroll()"
>
  <div class="container">
    <!-- Logo container-->
    <div class="pb-5">
      <a style="position: absolute" class="logo col-3" routerLink="/index">
        <img
          src="assets/images/blackRock-removebg-preview.png"
          class="l-dark logo-light-mode d-flex"
          alt=""
          height="150"
          width="150"
        />
      </a>
    </div>

    <!--end login button-->

    <div class="menu-extras">
      <div class="menu-item">
        <!-- Mobile menu toggle-->
        <a
          class="navbar-toggle"
          id="isToggle"
          (click)="toggleMenu()"
          [ngClass]="{ open: isCondensed === true }"
        >
          <div class="lines">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </a>
        <!-- End mobile menu toggle-->
      </div>
    </div>

    <div id="navigation">
      <ul class="navigation-menu" [class]="navClass">
        <li>
          <a routerLink="/index" fragment="Home" class="nav-link-ref">Home</a>
        </li>
        <!-- <li>
          <a
            href="javascript:void(0)"
            routerLink="/index"
            class="nav-link-ref"
            fragment="Clients"
            >Clients</a
          >
        </li> -->
        <li>
          <a
            href="javascript:void(0)"
            routerLink="/index"
            class="nav-link-ref"
            fragment="Company"
            >Company</a
          >
        </li>

        <li>
          <a
            href="javascript:void(0)"
            routerLink="/index"
            class="nav-link-ref"
            fragment="Projects"
            >Projects</a
          >
        </li>
        <li>
          <a
            href="javascript:void(0)"
            routerLink="/index"
            class="nav-link-ref"
            fragment="Contact"
            >Contact Us</a
          >
        </li>
      </ul>
      <!--end navigation menu-->
      <!-- <div class="buy-menu-btn d-none"></div> -->
      <!--end login button-->
      <!--end login button-->
    </div>
    <!--end navigation-->
  </div>
  <!--end container-->
</header>
<!--end header-->
<!-- Navbar End -->
