<div class="row justify-content-center">
  <div
    class="col-lg-2 col-md-2 col-6 text-center"
    *ngFor="let data of clients_logo; let isFirst = first"
  >
    <img
      src="{{ data.image }}"
      class="avatar"
      [ngClass]="isFirst ? 'col-8' : 'avatar-small'"
      alt=""
    />
  </div>
</div>
