<section class="bg-half-170 mb-5 d-table w-100" id="Home">
  <div class="container mb-5">
    <div class="row mt-5 align-items-center">
      <div class="col-lg-5 col-md-5">
        <div class="title-heading me-lg-4">
          <h1 class="heading mb-3">
            Our Creativity Is Your <span class="text-primary">Success</span>
          </h1>
          <p class="para-desc text-muted">
            We help you in the sea transportation process with our fleet of
            ships and our transportation strategies
          </p>
          <p></p>
          <div class="mt-4">
            <!-- <a routerLink="/page-contact-one" class="btn btn-primary mt-2 me-2"><i class="uil uil-envelope"></i> Get
                  Started</a>
                <a routerLink="/documentation" class="btn btn-outline-primary mt-2"><i class="uil uil-book-alt"></i>
                  Documentation</a> -->
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-7 col-md-7 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <img src="assets/images/AmaanBaghadShip.svg" alt="" />
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>

<div class="row text-center mt-5">
  <h4 class="fw-bold">Our Clients</h4>
  <p class="text-muted">We are happy to share work with our Clients</p>
</div>
<section class="py-4 border-bottom border-top" id="Clients">
  <div class="container">
    <app-clients-logo></app-clients-logo>
  </div>
  <!--end container-->
</section>

<section class="section bg-light border-bottom" id="Company">
  <div class="container">
    <!-- <div class="row justify-content-center">
          <div class="col-12 text-center">
            <div class="section-title mb-4 pb-2">
              <h4 class="title mb-4">How It Work ?</h4>
              <p class="text-muted para-desc mb-0 mx-auto">
                Start working with
                <span class="text-primary fw-bold">Landrick</span> that can provide
                everything you need to generate awareness, drive traffic, connect.
              </p>
            </div>
          </div>
        </div> -->
    <!--end col-->
    <!--end row-->

    <div class="row align-items-center">
      <div class="col-lg-5 col-md-6 mt-4 pt-2">
        <img
          class="col-12 rounded"
          height="400"
          src="assets/images/7.jpg"
          alt=""
        />
      </div>
      <!--end col-->

      <div class="col-lg-7 col-md-6 mt-4 pt-2">
        <div class="section-title ms-lg-5">
          <h4 class="title mb-4">Change the way you handle shipments</h4>
          <p class="text-muted">
            We are Black Rock for ship management & operation, have a strength network that developed within the local and international maritime industry has been very useful to be able to identify, secure and provide various types of small & medium range Oil & Chemical tankers. Depending on the requirement these have been sourced out on voyage, period, and time charters to customers both local and international.
           <br> We understand the varying needs of customers and so have ensured to serve them independent of their scale of operations. Our valuable relationships built over the years with many companies in the industry have quite helped us attain capability of delivering this service in a short span.
            Our Ship Management entails & services can be summarized in below:
            </p>
          <p class="text-primary fw-bold">Our Values:</p>
          <div class="row">
            <div class="col-12">
              <ul class="list-unstyled text-muted">
                <li class="mb-0">
                  <span class="text-primary h5 me-2"
                    ><i class="uil uil-check-circle align-middle"></i></span
                  >Registering and documenting the ship as required by national and international authorities
                </li>
                <li class="mb-0">
                  <span class="text-primary h5 me-2"
                    ><i class="uil uil-check-circle align-middle"></i></span
                  >Crewing, victualling, stores, spare parts, maintenance, and repairs
                </li>
                <li class="mb-0">
                  <span class="text-primary h5 me-2"
                    ><i class="uil uil-check-circle align-middle"></i></span
                  >Training the crew
                </li>
                <li class="mb-0">
                  <span class="text-primary h5 me-2"
                    ><i class="uil uil-check-circle align-middle"></i></span
                  >Arranging insurance and handling claims and recoveries
                </li>
                <li class="mb-0">
                  <span class="text-primary h5 me-2"
                    ><i class="uil uil-check-circle align-middle"></i></span
                  >Appointing surveyors
                </li>
                <li class="mb-0">
                  <span class="text-primary h5 me-2"
                    ><i class="uil uil-check-circle align-middle"></i></span
                  >Ensuring safety of the crew and engineering systems
                </li>
                <li class="mb-0">
                  <span class="text-primary h5 me-2"
                    ><i class="uil uil-check-circle align-middle"></i></span
                  >Ensuring the ship follows the time schedule, and a variety of other tasks designed to keep the ship running smoothly.
                </li>
              </ul>
            </div>
           
          </div>

          <!-- <a href="javascript:void(0)" class="mt-3 h6 text-primary"
                >Find Out More <i class="uil uil-angle-right-b align-middle"></i
              ></a> -->
        </div>
      </div>
      <!--end col-->
    </div>

    <div class="row mt-5 align-items-center">
      <div class="col-lg-5 col-md-6 mt-4 pt-2">
        <img
          class="col-12 rounded"
          height="400"
          src="assets/images/9.jpg"
          alt=""
        />
      </div>
      <!--end col-->

      <div class="col-lg-7 col-md-6 mt-4 pt-2">
        <div class="section-title ms-lg-5">
          <div class="row justify-content-center">
            <div class="col-6">
              <h4 class="title mb-2">Our Mission</h4>
              <p class="text-muted justify-content-center">
                Transforming businesses using our pioneering approach, along
                with modern, safe technology and exceptional services, with a
                team that truly cares.
              </p>
            </div>
            <div class="col-6">
              <h4 class="fw-bold">Our Vision:</h4>
              <p class="text-muted">
                We inspire every work the company does for success and
                continuous improvement through innovation.
              </p>
            </div>
          </div>

          <div class="row">
            <h4 class="fw-bold text-primary">Services interface:</h4>
            <div class="col-6">
              <ul class="list-unstyled text-muted">
                <li class="mb-0">
                  <span class="text-primary h5 me-2"
                    ><i class="uil uil-check-circle align-middle"></i></span
                  >Shipping Agency Service .
                </li>
                <li class="mb-0">
                  <span class="text-primary h5 me-2"
                    ><i class="uil uil-check-circle align-middle"></i></span
                  >Maritime agencies .
                </li>
                <li class="mb-0">
                  <span class="text-primary h5 me-2"
                    ><i class="uil uil-check-circle align-middle"></i></span
                  >Insurance surveys.
                </li>
                <li class="mb-0">
                  <span class="text-primary h5 me-2"
                    ><i class="uil uil-check-circle align-middle"></i></span
                  >Container and containerized Cargo-Containers.
                </li>

                <li class="mb-0">
                  <span class="text-primary h5 me-2"
                    ><i class="uil uil-check-circle align-middle"></i></span
                  >custom clearance - Public Transport .
                </li>

                <li class="mb-0">
                  <span class="text-primary h5 me-2"
                    ><i class="uil uil-check-circle align-middle"></i></span
                  >General cargo inspection.
                </li>
              </ul>
            </div>
            <div class="col-6">
              <ul class="list-unstyled text-muted">
                <!-- <li class="mb-0">
                      <span class="text-primary h5 me-2"
                        ><i class="uil uil-check-circle align-middle"></i></span
                      >Positive.
                    </li> -->
                <li class="mb-0">
                  <span class="text-primary h5 me-2"
                    ><i class="uil uil-check-circle align-middle"></i></span
                  >Quantity and quality inspection for oil products .
                </li>
                <li class="mb-0">
                  <span class="text-primary h5 me-2"
                    ><i class="uil uil-check-circle align-middle"></i></span
                  >Loading/discharge Supervision-Condition survey .
                </li>
                <li class="mb-0">
                  <span class="text-primary h5 me-2"
                    ><i class="uil uil-check-circle align-middle"></i></span
                  >Bunker Survey .
                </li>

                <li class="mb-0">
                  <span class="text-primary h5 me-2"
                    ><i class="uil uil-check-circle align-middle"></i></span
                  >Ship to Ship (STS).
                </li>
                <li class="mb-0">
                  <span class="text-primary h5 me-2"
                    ><i class="uil uil-check-circle align-middle"></i></span
                  >Marine & Commercial Supply .
                </li>
                <li class="mb-0">
                  <span class="text-primary h5 me-2"
                    ><i class="uil uil-check-circle align-middle"></i></span
                  >Salvage .
                </li>
                <li class="mb-0">
                  <span class="text-primary h5 me-2"
                    ><i class="uil uil-check-circle align-middle"></i
                  ></span>
                  Brokers (Ships Selling/Buying) .
                </li>
              </ul>
            </div>
          </div>
          <!-- <a href="javascript:void(0)" class="mt-3 h6 text-primary"
                >Find Out More <i class="uil uil-angle-right-b align-middle"></i
              ></a> -->
        </div>
      </div>
      <!--end col-->
    </div>

    <!--end row-->
  </div>
  <!--end container-->

  <!--end container-->
</section>

<section class="section bg-light border-bottom" id="Projects">
  <div class="container mt-100 mt-60" id="Contracts">
    <div class="row align-items-center">
      <div
        class="col-lg-7 col-md-6 order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0"
      >
        <div class="section-title">
          <h4 class="title col-12 mb-4">
            <strong>Our projects</strong> cooperation with
            <span class="text-primary">
              Iraqi State Maritime Transportation</span
            >
            (SCMT)
          </h4>

          <p class="text-muted">
            SCMT and Black Rock Company have signed an 6-month agreement to
            support a new project relating to fuel oil transport and storage
            from Khor Al Zubair and Umm Qasr oil terminals in Iraq. The project
            represents a significant extension of Black Rock portfolio of
            dedicated products and services into the oil and gas sector and is
            part of its ongoing expansion in the key market of Iraq. Under the
            terms of the agreement, Black Rock, will manage the entire project,
            providing three Panamax and Medium Range (MR) tanker. Working with
            SCMT, Black Rock will transport fuel oil from terminals in Port of
            Khor Al Zubair and Umm Qasr South Port to Iraqi territorial waters
            using the LR1 & MR tanker. The fuel oil will then be transferred and
            stored on the VLCCs, which will serve as floating fuel tanks with
            monthly delivery capacity of 750,000 tons. The 6-month agreement is
            secured at competitive rates, providing flexibility for the Iraqi
            partners, and ensuring a favorable rate of return for Black Rock.
            Fadie Sakka, Acting CEO of Black Rock, said: “We have been fortunate
            enough to be awarded some fantastic contracts with SCMT and we are
            delighted to welcome many new clients to our business. We have also
            been able to introduce some very strong complementary acquisitions
            into a major fuel oil transport and storage project in Iraq. The
            energy sector is an important growth market for Black Rock, and this
            project demonstrates our expertise in this area as well as the
            extent and sophistication of our fleet. This agreement underlines
            our capacity to tailor solutions to the specific needs of companies
            in the oil and gas industry and marks an important extension of our
            presence in Iraq.”
          </p>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-5 col-md-6 order-1 order-md-2">
        <div class="card rounded border-0 shadow ms-lg-5">
          <img
            class="col-12 rounded"
            height="500"
            src="assets/images/22.jpg"
            alt=""
          />
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
</section>

<section class="section bg-light">
  <div class="container">
    <div
      class="row my-md-5 pt-md-3 my-4 pt-2 pb-lg-4 justify-content-center"
      id="Contact"
    >
      <div class="col-12 text-center">
        <div class="section-title">
          <h4 class="title mb-4">Have Question ? Get in touch!</h4>

          <a [routerLink]="'/contact'" class="btn btn-primary mt-4"
            ><i class="mdi mdi-phone"></i> Contact us</a
          >
        </div>
      </div>
    </div>
  </div>
</section>

<div class="position-relative">
  <div class="shape overflow-hidden text-footer">
    <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="#d0cbcb"></path>
    </svg>
  </div>
</div>

<a
  [ngxScrollTo]="'#Home'"
  id="back-to-top"
  class="btn btn-icon btn-primary text-center justify-content-center back-to-top"
>
  <i-feather name="arrow-up" class="icons text-center"></i-feather>
</a>
