<!-- Start Contact -->
<section class="section pt-5 mt-4" id="home">
  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div
        class="col-lg-5 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0 order-2 order-md-1"
      >
        <div class="card custom-form rounded border-0 shadow p-4">
          <form method="post" name="myForm" (onsubmit)="validateForm()">
            <p id="error-msg" class="mb-0"></p>
            <div id="simple-msg"></div>
            <div class="row">
              <div class="col-md-6">
                <div class="mb-3">
                  <label class="form-label"
                    >Your Name <span class="text-danger">*</span></label
                  >
                  <div class="form-icon position-relative">
                    <i-feather
                      name="user"
                      class="fea icon-sm icons"
                    ></i-feather>
                    <i class="featger icon-sm icons"> </i>
                    <input
                      name="name"
                      id="name"
                      type="text"
                      [(ngModel)]="name"
                      class="form-control ps-5"
                      placeholder="Name :"
                    />
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="mb-3">
                  <label class="form-label"
                    >Your Email <span class="text-danger">*</span></label
                  >
                  <div class="form-icon position-relative">
                    <i-feather
                      name="mail"
                      class="fea icon-sm icons"
                    ></i-feather>
                    <input
                      name="email"
                      id="email"
                      [(ngModel)]="email"
                      type="email"
                      class="form-control ps-5"
                      placeholder="Email :"
                    />
                  </div>
                </div>
              </div>
              <!--end col-->
              <div class="col-md-6">
                <div class="mb-3">
                  <label class="form-label"
                    >Your PhoneNumber <span class="text-danger">*</span></label
                  >
                  <div class="form-icon position-relative">
                    <i-feather
                      name="mail"
                      class="fea icon-sm icons"
                    ></i-feather>
                    <input
                      name="PhoneNumber"
                      id="PhoneNumber"
                      [(ngModel)]="PhoneNumber"
                      class="form-control ps-5"
                      placeholder="PhoneNumber :"
                    />
                  </div>
                </div>
              </div>

              <div class="col-12">
                <div class="mb-3">
                  <label class="form-label">Subject</label>
                  <div class="form-icon position-relative">
                    <i-feather
                      name="book"
                      class="fea icon-sm icons"
                    ></i-feather>
                    <input
                      name="subject"
                      id="subject"
                      [(ngModel)]="subject"
                      class="form-control ps-5"
                      placeholder="subject :"
                    />
                  </div>
                </div>
              </div>
              <!--end col-->

              <div class="col-12">
                <div class="mb-3">
                  <label class="form-label"
                    >Request <span class="text-danger">*</span></label
                  >
                  <div class="form-icon position-relative">
                    <i-feather
                      name="message-circle"
                      class="fea icon-sm icons clearfix"
                    ></i-feather>
                    <textarea
                      name="comments"
                      id="comments"
                      [(ngModel)]="comments"
                      rows="4"
                      class="form-control ps-5"
                      placeholder="Message :"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="d-grid">
                  <button
                    type="submit"
                    id="submit"
                    name="send"
                    [disabled]="!email || !PhoneNumber || !comments || !name"
                    class="btn btn-primary"
                    (click)="validateForm()"
                  >
                    Send Message
                  </button>
                </div>
              </div>
              <!--end col-->
            </div>
            <!--end row-->
          </form>
        </div>
        <!--end custom-form-->
      </div>
      <!--end col-->

      <div class="col-lg-7 col-md-6 order-1 order-md-2">
        <div class="title-heading ms-lg-4">
          <h4 class="mb-4">Contact Details</h4>
          <!-- <p class="text-muted">Start working with <span class="text-primary fw-bold">Landrick</span> that can provide
            everything you need to generate awareness, drive traffic, connect.</p> -->
          <div class="d-flex contact-detail align-items-center mt-3">
            <div class="icon">
              <i-feather
                name="mail"
                class="fea icon-m-md text-dark me-3"
              ></i-feather>
            </div>
            <div class="flex-1 content">
              <h6 class="title fw-bold mb-0">Email</h6>
              <a href="mailto:contact@example.com" class="text-primary"
                >info@blackrockshipco.com</a
              >
            </div>
          </div>

          <div class="d-flex contact-detail align-items-center mt-3">
            <div class="icon">
              <i-feather
                name="phone"
                class="fea icon-m-md text-dark me-3"
              ></i-feather>
            </div>
            <div class="flex-1 content">
              <h6 class="title fw-bold mb-0">Phone</h6>
              <a href="tel:+964 782 311 4191" class="text-primary"
                >+971 507 192 475</a
              >
            </div>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End contact -->

<!-- Back to top -->
<a
  href="javascript: void(0);"
  class="btn btn-icon btn-primary back-to-top"
  id="back-to-top"
  [ngxScrollTo]="'#home'"
>
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->
