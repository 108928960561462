import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as emailjs from "@emailjs/browser";
@Component({
  selector: "app-page-contact-two",
  templateUrl: "./page-contact-two.component.html",
  styleUrls: ["./page-contact-two.component.css"],
})

/**
 * Page Contact-Two Component
 */
export class PageContactTwoComponent implements OnInit {
  comments;
  subject;
  email;
  name;
  PhoneNumber;
  constructor(private modalService: NgbModal) {
    emailjs.init("TjU50uAdt3wQk1dGW");
  }

  ngOnInit(): void {
    emailjs.init("TjU50uAdt3wQk1dGW");
  }

  mapView(content) {
    this.modalService.open(content, {
      windowClass: "dark-modal",
      size: "lg",
      centered: true,
    });
  }
  validateForm() {
    if (this.email && this.PhoneNumber && this.comments && this.name) {
      emailjs
        .send(
          "service_0ikht5b",
          "template_87u5euc",
          {
            from_name: this.name,
            to_name: "Black Rock Admin",
            message: this.comments,
            reply_to: "",
            email: this.email,
            subject: this.subject,
            PhoneNumber: this.PhoneNumber,
          },
          "TjU50uAdt3wQk1dGW"
        )
        .then((e: any) => {
          alert("your message was sent");
          this.email = "";
          this.PhoneNumber = "";
          this.comments = "";
          this.name = "";
          this.subject = "";
        })
        .catch((e: any) => {
          alert("Sorry Something is Wrong , Please Try Again...");
        });
    } else alert("Something is Wrong");
  }
}
