<app-header
  [navClass]="addclass"
  [buttonList]="buttonShow"
  [sliderTopbar]="TopbarShow"
  [isdeveloper]="developerPage"
  [shopPages]="shopPages"
  [isActive]="current"
></app-header>
<router-outlet></router-outlet>
<app-footer
  [footerVariant]="footerClass"
  [hideFooter]="hideFooter"
></app-footer>
