import { Component, OnInit } from "@angular/core";

interface client {
  image: string;
}

@Component({
  selector: "app-clients-logo",
  templateUrl: "./clients-logo.component.html",
  styleUrls: ["./clients-logo.component.css"],
})
export class ClientsLogoComponent implements OnInit {
  /**
   * Clients Logo
   */
  clients_logo: client[] = [
    {
      image: "assets/images/SCMT.jpg",
    },
    {
      image:
        "https://www.galaxy-oil.com/wp-content/uploads/2017/06/Galaxy-Oil-Modified-SMALL.png",
    },
    {
      image:
        "https://media.licdn.com/dms/image/C4D0BAQFoVIJOAT6Rrw/company-logo_200_200/0/1624093340912?e=1688601600&v=beta&t=vJAlK-d4Vvqc0eRhSbUMHXkUS_JcIWaHL1prDUbsPZQ",
    },
    {
      image:
        "//img1.wsimg.com/isteam/ip/f1e29bec-9d11-4fd5-b224-bc0637ec3834/Artboard%201.png/:/rs=h:149,cg:true,m/qt=q:95",
    },
    // {
    //   image: "assets/images/client/shopify.svg"
    // },
    // {
    //   image: "assets/images/client/spotify.svg"
    // }
  ];

  constructor() {}

  ngOnInit(): void {}
}
